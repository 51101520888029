@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	font-size: 16px;
	scroll-behavior: smooth;
}

body {
	background: hsla(204, 14%, 21%, 1);

	background: linear-gradient(
		90deg,
		hsla(204, 14%, 21%, 1) 0%,
		hsla(206, 7%, 21%, 1) 100%
	);

	background: -moz-linear-gradient(
		90deg,
		hsla(204, 14%, 21%, 1) 0%,
		hsla(206, 7%, 21%, 1) 100%
	);

	background: -webkit-linear-gradient(
		90deg,
		hsla(204, 14%, 21%, 1) 0%,
		hsla(206, 7%, 21%, 1) 100%
	);

	filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#2E373D", endColorstr="#313538", GradientType=1 );
}

h1 {
	font-weight: 600;
}

h2,
h3,
h4,
h5 {
	font-weight: 500;
}
